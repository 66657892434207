import { CoordinationClient, type CoordinationClientType } from '@atlassiansox/engagekit-ts';
import type { envType } from '@atlassian/ipm-choreographer';
import { ProductIds } from '@atlassian/ipm-choreographer';

const noop = async () => false;

export const getCoordinationClient = (
	disableEngagementForTests: boolean,
	cloudId: string,
	workspaceId: string,
	enableChoreographer: boolean,
	environment: envType,
): CoordinationClientType => {
	if (disableEngagementForTests) {
		return { start: noop, stop: noop };
	} else {
		const coordinationOptions = {
			enableChoreographer,
			envType: environment,
			additionalData: { sourceProductId: ProductIds.CONFLUENCE },
		};

		return new CoordinationClient({
			cloudId,
			stargateUrl: '/gateway/api',
			isFedRAMPEnvironment: false,
			workspaceId,
			coordinationOptions,
		});
	}
};
