import gql from 'graphql-tag';

import { WatchDialogContentFragment } from './WatchDialogContent.fragment';

/*
   DO NOT DIRECTLY IMPORT THESE QUERIES INTO YOUR COMPONENT
   Use the utility method getWatchDialogQuery to ensure you receive
   the correct watch dialog query
*/

export const WatchDialogQuery = gql`
	query WatchDialogQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			...WatchDialogContentFragment
		}
	}

	${WatchDialogContentFragment}
`;

// same as WatchDialogQuery but with:
// - currentUserIsWatchingChildren
// - currentUserHasAncestorWatchingChildren
export const WatchDialogQueryWithWatchChildren = gql`
	query WatchDialogQueryWithWatchChildren($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			...WatchDialogContentFragmentWithWatchChildren
		}
	}

	fragment WatchDialogContentFragmentWithWatchChildren on Content {
		id
		currentUserIsWatching
		currentUserIsWatchingChildren
		currentUserHasAncestorWatchingChildren
		type
		space {
			id
			key
			currentUser {
				isWatched
				isWatchingBlogs
			}
			operations {
				operation
				targetType
			}
		}
	}
`;
