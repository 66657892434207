export enum UpdateContentType {
	CONTENT = 'content',
	CHILDREN = 'children',
	BLOGS_IN_SPACE = 'blogs',
	SPACE = 'space',
}

export enum UpdateType {
	WATCH = 'watch',
	UNWATCH = 'unwatch',
}
