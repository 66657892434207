import React, { Suspense, lazy } from 'react';

import type { ThirdPartyNudgeProps } from './main';

const ThirdPartyNudge = lazy(() =>
	import(/* webpackChunkName: "loadable-ThirdPartyNudge" */ './main').then(
		({ ThirdPartyNudge }) => ({
			default: ThirdPartyNudge,
		}),
	),
);

const NotificationNudge = lazy(() =>
	import(/* webpackChunkName: "loadable-NotificationNudge" */ './main').then(
		({ NotificationNudge }) => ({
			default: NotificationNudge,
		}),
	),
);

export const AsyncWrapper: React.FC<ThirdPartyNudgeProps> = (props) => (
	<Suspense fallback={null}>
		<ThirdPartyNudge {...props} />
	</Suspense>
);

export const NotificationNudgeAsyncWrapper: React.FC = () => (
	<Suspense fallback={null}>
		<NotificationNudge />
	</Suspense>
);
