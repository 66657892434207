import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { css } from '@compiled/react';

import AkSpinner from '@atlaskit/spinner/spinner';

export const SIZES = {
	small: 12,
	medium: 26,
	large: 36,
};

const wrapperStyles = css({
	width: '100%',
	height: '100%',
	position: 'relative',
});

const spinnerStyles = css({
	position: 'absolute',
	left: '50%',
	top: '50%',
	zIndex: 100,
});

class CenteredSpinner extends PureComponent {
	static displayName = 'CenteredSpinner';
	static propTypes = {
		/**
		 * custom spinner style
		 */
		style: PropTypes.object,

		/**
		 * Size of spinner
		 */
		size: PropTypes.oneOf(['small', 'medium', 'large']),
	};

	static defaultProps = {
		size: 'small',
	};

	render() {
		const { style, size } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={style} css={wrapperStyles} data-test-id="wrapper-spinner">
				<div
					data-test-id="spinner"
					css={spinnerStyles}
					style={{ marginLeft: `${-SIZES[size] / 2}px`, marginTop: `${-SIZES[size] / 2}px` }}
				>
					<AkSpinner size={SIZES[size]} />
				</div>
			</div>
		);
	}
}

export { CenteredSpinner };
