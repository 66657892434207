import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { Query } from 'react-apollo';

import { withAnalyticsEvents } from '@atlaskit/analytics-next';

import { markErrorAsHandled } from '@confluence/graphql';
import { SessionData } from '@confluence/session-data';
import { FlagsStateContainer } from '@confluence/flags';
import { Attribution, ErrorBoundary, isUnauthorizedError } from '@confluence/error-boundary';
import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog/entry-points/manage-watcher-tab-types';
import { getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery } from '@confluence/query-preloader-tools';

import { CONFLUENCE_EDITION } from './watch-constants';
import { WatchDialogComponent } from './WatchDialogComponent';
import { WatchButtonPlaceholder } from './WatchButtonPlaceholder';
import { getWatchDialogQuery } from './getWatchDialogQuery';

const isUserAdmin = (operations) => {
	return Boolean(
		operations.find((elem) => elem.targetType === 'space' && elem.operation === 'administer'),
	);
};

export const getWatchQueryData = (data) => {
	const spaceId = data?.singleContent?.space?.id;
	const contentType = data?.singleContent?.type;
	const isCurrentUserWatching = data?.singleContent?.currentUserIsWatching;
	const isWatchingChildren = data?.singleContent?.currentUserIsWatchingChildren;
	const hasAncestorWatchingChildren = data?.singleContent?.currentUserHasAncestorWatchingChildren;
	const isWatchingBlogs = data?.singleContent?.space?.currentUser?.isWatchingBlogs;
	const isAdmin = data?.singleContent?.space?.operations || [];
	const isWatchingSpace = data?.singleContent?.space?.currentUser?.isWatched;

	return {
		spaceId,
		contentType,
		isCurrentUserWatching,
		isWatchingChildren,
		hasAncestorWatchingChildren,
		isWatchingBlogs,
		isAdmin,
		isWatchingSpace,
	};
};

export const WatchDialog = withAnalyticsEvents()(function WatchDialog({
	contentId,
	defaultTab,
	customPlacement,
	onRender = () => {},
	customTrigger,
	compact,
	useShortcut = true,
	componentLocation,
	isDisabled,
}) {
	if (!contentId) {
		return null;
	}

	const shouldSSRWatchButton = Boolean(
		window.__SSR_RENDERED__ &&
			(defaultTab === MANAGE_WATCHER_TAB_TYPES.PAGE ||
				defaultTab === MANAGE_WATCHER_TAB_TYPES.SPACE),
	);

	return (
		<ErrorBoundary attribution={Attribution.COLLABORATION}>
			<SessionData>
				{({ isLicensed, userId, edition }) => {
					if (!userId) {
						return null;
					}

					const skipTrialDetailsQuery = !isLicensed || edition === CONFLUENCE_EDITION.FREE;

					return (
						<Subscribe to={[FlagsStateContainer]}>
							{(flagsContainer) => (
								<Query
									{...getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery({
										query: getWatchDialogQuery(),
										variables: { contentId },
										skip: !isLicensed,
										context: { single: true },
										// No fetch policy here because we are relying on network-only preloader to get the latest data
									})}
								>
									{({ data, loading, error }) => {
										if (error) {
											if (isUnauthorizedError(error)) {
												markErrorAsHandled(error);
											}
											return null;
										}

										if (loading) {
											return <WatchButtonPlaceholder compact={compact} />;
										}

										const {
											spaceId,
											contentType,
											isCurrentUserWatching,
											isWatchingChildren,
											hasAncestorWatchingChildren,
											isWatchingBlogs,
											isAdmin,
											isWatchingSpace,
										} = getWatchQueryData(data);

										const params = {
											isWatchingContent: isCurrentUserWatching,
											isWatchingChildren,
											hasAncestorWatchingChildren,
											isWatchingBlogs,
											isWatchingSpace,
											isAdmin: isUserAdmin(isAdmin),
											edition,
										};

										onRender();
										return (
											<WatchDialogComponent
												contentId={contentId}
												spaceId={spaceId}
												params={params}
												defaultTab={defaultTab}
												flagsContainer={flagsContainer}
												customPlacement={customPlacement}
												isDisabled={!isLicensed || isDisabled}
												customTrigger={customTrigger}
												useShortcut={useShortcut}
												shouldSSRWatchButton={shouldSSRWatchButton}
												skipTrialDetailsQuery={skipTrialDetailsQuery}
												contentType={contentType}
												componentLocation={componentLocation}
											/>
										);
									}}
								</Query>
							)}
						</Subscribe>
					);
				}}
			</SessionData>
		</ErrorBoundary>
	);
});
