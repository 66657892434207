import type { VFC } from 'react';
import { memo, useCallback } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import type { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import { VIEW_BLOG, VIEW_PAGE } from '@confluence/named-routes';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { SSRMeasures } from '@confluence/action-measures';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import type { ActionTiming } from './usePerformanceAction';
import { usePerformanceAction } from './usePerformanceAction';
import { PAGE_SEGMENT_MARK_PREFIX_END } from './metric-keys';
import { browserMetricsState } from './utils';
import { getViewTransitionSource } from './viewTransitionSource';

export type Props = {
	/**
	 * The metric to stop.
	 */
	metric: PageSegmentLoadMetric;
	/**
	 * A callback that is fired right after the metric is started.
	 */
	onComplete?: () => void;
	/**
	 * A override to use for the metric stop time.
	 */
	stopTime?: number;
	/**
	 * The timing to use when starting the metric.
	 *
	 */
	timing?: ActionTiming;
	/**
	 * Custom data to add to the event's payload
	 */
	customData?: any;
	/**
	 * OnComplete might have sideeffects like measuring performance.
	 * This function gives an opportunity to enrich customData with more fields after the "onComplete" call
	 */
	augmentCustomDataAfterComplete?: (customData: any) => any;
};

const noop = () => {};

const PageSegmentLoadEndComponent: VFC<Props> = memo(
	({
		customData = {},
		metric,
		onComplete = noop,
		stopTime,
		timing,
		augmentCustomDataAfterComplete,
	}) => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR) {
			SSRMeasures.markMeasureEnd(`ssr-app/render/${metric.key}`);
		}

		const handlePageSegmentLoadMetricStop = useCallback(() => {
			if (metric) {
				onComplete();
				let finalCustomData;
				if (augmentCustomDataAfterComplete) {
					finalCustomData = augmentCustomDataAfterComplete(customData);
				} else {
					finalCustomData = customData;
				}

				const { route } = metric.getData();

				const shouldAddViewTransitionSource = route === VIEW_PAGE.name || route === VIEW_BLOG.name;
				if (shouldAddViewTransitionSource) {
					finalCustomData.viewTransitionSource = getViewTransitionSource();
				}

				browserMetrics.getPageLoadMetric().mark(`${PAGE_SEGMENT_MARK_PREFIX_END}:${metric.key}`);
				metric.stop({
					customData: {
						...finalCustomData,
						previousRoute: browserMetricsState.previousRoute,
						previousContentId: browserMetricsState.previousContentId,
						pageLoadInfo: getUniquePageLoadId(),
					},
					stopTime,
				});
			}
			// Not adding exhaustive dependencies because we want this to fire only once,
			// despite any changes to `customData` or `stopTime`
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [metric, onComplete]);

		usePerformanceAction(handlePageSegmentLoadMetricStop, timing);
		return null;
	},
);

export const PageSegmentLoadEnd = withTransparentErrorBoundary({
	attribution: Attribution.BACKBONE,
})(PageSegmentLoadEndComponent);
