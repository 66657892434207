import { fg } from '@confluence/feature-gating';

import { WatchDialogQuery, WatchDialogQueryWithWatchChildren } from './WatchDialogQuery.graphql';

export const getWatchDialogQuery = () => {
	if (fg('confluence_watch_children_pages')) {
		return WatchDialogQueryWithWatchChildren;
	}

	return WatchDialogQuery;
};
