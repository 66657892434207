import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { WatchDialog } from './WatchDialog';

export class WatchDialogWrapper extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string.isRequired,
		defaultTab: PropTypes.number.isRequired,
		componentLocation: PropTypes.number,
		isDisabled: PropTypes.bool,
	};

	render() {
		const { contentId, defaultTab, componentLocation, isDisabled } = this.props;

		return (
			<WatchDialog
				contentId={contentId}
				defaultTab={defaultTab}
				customPlacement="bottom"
				onRender={this.buttonRendered}
				componentLocation={componentLocation}
				isDisabled={isDisabled}
			/>
		);
	}
}
