export const isMac = () => window.navigator && window.navigator.userAgent?.indexOf('Mac') > -1;

export const GLYPHS = {
	command: '\u2318', // ⌘
	shift: '\u21E7', // ⇧
};

// this is used for star in overflow menu when confluence_frontend_object_header is on.
// We only want to show the ctrl and option unicode keys there.
export const GLYPHS_WITH_CTRL_OPTION_UNICODE = {
	command: '\u2318', // ⌘
	shift: '\u21E7', // ⇧
	ctrl: '\u2303', // ⌃
	option: '\u2325', // ⌥
};

const keysStrings: { [key: string]: string | undefined } = {
	command: GLYPHS.command,
	enter: 'Enter',
	shiftMac: GLYPHS.shift,
	shift: 'Shift',
	ctrl: 'Ctrl',
	option: 'Option',
	alt: 'Alt',
};

const keysStringsUnicode: { [key: string]: string | undefined } = {
	command: GLYPHS_WITH_CTRL_OPTION_UNICODE.command,
	enter: 'Enter',
	shiftMac: GLYPHS_WITH_CTRL_OPTION_UNICODE.shift,
	shift: 'Shift',
	ctrl: GLYPHS_WITH_CTRL_OPTION_UNICODE.ctrl,
	option: GLYPHS_WITH_CTRL_OPTION_UNICODE.option,
	alt: 'Alt',
};

const osMap: { [key: string]: { mac: string; other?: string } | undefined } = {
	mod: {
		mac: 'command',
		other: 'ctrl',
	},
	shift: {
		mac: 'shiftMac',
	},
	option: {
		mac: 'option',
		other: 'alt',
	},
};

const getGlue = () => (isMac() ? ' ' : '+');

export const getShortcutString = (
	keysStr: string,
	stringsGlue = getGlue(),
	isAllUnicode?: boolean,
): string => {
	const shortcutDict = isAllUnicode ? keysStringsUnicode : keysStrings;
	const keyGlyphs = isAllUnicode ? GLYPHS_WITH_CTRL_OPTION_UNICODE : GLYPHS;
	const os = isMac() ? 'mac' : 'other';
	let stringsCount = 0;
	const shortcutPieces = keysStr.split('+');
	return shortcutPieces
		.map((key) => {
			key = osMap[key]?.[os] ?? key;

			if (!(key in keyGlyphs)) {
				stringsCount += 1;
			}
			return shortcutDict[key] ?? (key.length === 1 ? key.toUpperCase() : key);
		})
		.join(stringsCount > 1 || shortcutPieces.length > 2 ? stringsGlue : '');
};

export const getShortcutStrings = (
	keysStr: string,
	stringsGlue = getGlue(),
	isAllUnicode?: boolean,
): string[] => {
	return getShortcutString(keysStr, stringsGlue, isAllUnicode)?.split(stringsGlue);
};
